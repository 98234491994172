import React from "react";

import { 
    Layout,
    SimplePage
} from "../components";

const PrivacyPage = () => {

    const blocks = [
        {
            content: 'We collect information that you provide when you sign in using your Steam account, participate in our games and contests, and communicate with us. This information may include your name, email address, and Steam account information.',
            title: 'Collection of Information',
        },
        {
            content: 'We use the information we collect to provide you with access to our website and games, communicate with you, and deliver prizes. We may also use your information to improve our website and services.',
            title: 'Use of Information',
        },
        {
            content: 'We may disclose your information to third-party service providers that help us deliver our services, such as prize fulfillment providers. We may also disclose your information to comply with legal requirements, such as a court order.',
            title: 'Disclosure of Information',
        },
        {
            content: 'We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.',
            title: 'Security of Information',
        },
        {
            content: 'We retain your personal information for as long as necessary to provide our services, fulfill our legal obligations, and resolve disputes.',
            title: 'Retention of Information',
        },
        {
            content: 'Our website is not directed to children under the age of 18. We do not knowingly collect personal information from children under the age of 18.',
            title: 'Children\'s Privacy',
        },
        {
            content: 'You can control cookies through your browser settings. Most browsers allow you to refuse cookies or to delete cookies that have been placed on your device. However, some features of our website may not function properly if you disable cookies.',
            title: 'Controlling Cookies',
        },
        {
            content: 'We may update this privacy statement from time to time. The revised statement will be posted on our website with an updated date.',
            title: 'Changes to this Privacy Statement',
        },
        /*{
            action: {
                label: 'support@playdrop.gg',
                onClick: () => window.open('mailto:support@playdrop.gg', '_blank'),
                type: 'inline',
            },
            content: 'If you have any questions or concerns about our privacy practices, please contact us at:',
        },*/
        {
            action: {
                label: 'contact us on our Discord channel',
                onClick: () => window.open('https://discord.com/invite/cPJYPhfPwQ', '_blank'),
                type: 'inline',
            },
            content: [
                'If you have any questions or concerns about our privacy practices, please',
            ],
        },
    ];
    const title = "Privacy Policy";

    return (
        <Layout 
            title="Privacy Policy"
        >
            <SimplePage
                blocks={blocks}
                title={title}
            />
        </Layout>
    )
}

export default PrivacyPage;